export default [
  {
    path: "/login",
    name: "login",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/auth/loginView.vue"),
    meta: {
      title: "t:titles.login",
      hideNavbar: true,
    },
  },
];
