export default [
  {
    path: "/",
    name: "home",
    component: () =>
      import(/* webpackChunkName: "dashboard" */ "../views/home/homeView.vue"),
    meta: {
      requiresAuth: true,
      title: "t:titles.overview",
    },
  },
  {
    path: "/test",
    name: "test",
    component: () =>
      import(/* webpackChunkName: "dashboard" */ "../views/home/testView.vue"),
    meta: {
      requiresAuth: false,
      title: "t:titles.overview",
    },
  },
  {
    path: "/configuration/smart-roomhub",
    name: "sync",
    component: () =>
      import(
        /* webpackChunkName: "config" */ "../views/configuration/syncView.vue"
      ),
    meta: {
      requiresAuth: true,
      hideNavbar: true,
    },
  },
  {
    path: "/console",
    name: "console",
    component: () =>
      import(
        /* webpackChunkName: "console" */ "../views/logger/loggerIndex.vue"
      ),
    meta: {
      requiresAuth: true,
      title: "t:titles.logger",
    },
  },
];
