export default {
  api: {
    url: process.env.VUE_APP_URL
  },
  currentGroup: null,
  sidebarState: false,
  devices: [],
  groups: [],
  devicesStatus: [],
  accountData: {},
  buildingData: {
    vent: {
      x: new Array(10),
      y: new Array(10),
    },
    heat: {
      x: new Array(10),
      y: new Array(10),
    },
    cool: {
      x: new Array(10),
      y: new Array(10),
    },
    occupancy: {
      x: new Array(10),
      y: new Array(10),
    },
  },
  sensorsData: [],
  data: {
    current: -1,
    currentBackup: null,
    currentMeasure: {
      type: "device",
      source: "",
      interval: "hourly",
      data_type: "sensor",
      sensor: "temp",
      range: {
        mode: "",
        value: [],
      },
      measurement: [{
          date: 1,
          value: 2,
        },
        {
          date: 2,
          value: 1,
        },
      ],
    },
    sources: [],
  },
  authorized: false,
  loadingDataModalVisible: false,
};