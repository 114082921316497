import i18n from "@/i18n";
const { t } = i18n.global;

export default [
  {
    path: "/configuration/:type?/:id?",
    name: "config",
    component: () =>
      import(
        /* webpackChunkName: "dashboard" */ "../views/configuration/indexView.vue"
      ),
    meta: {
      requiresAuth: true,
      title: (params) => t("configuration.titles.configuration", params),
    },
  },
  {
    path: "/configuration/system-info/:type?/:id?",
    name: "config.system-info",
    component: () =>
      import(
        /* webpackChunkName: "dashboard" */ "../views/configuration/systemInfoView.vue"
      ),
    meta: {
      requiresAuth: true,
      title: (params) => t("configuration.titles.configuration", params),
    },
  },
  {
    path: "/configuration/wifi/:type?/:id?",
    name: "config.wifi",
    component: () =>
      import(
        /* webpackChunkName: "dashboard" */ "../views/configuration/WifiView.vue"
      ),
    meta: {
      requiresAuth: true,
      title: (params) => t("configuration.titles.wifi", params),
    },
  },
  {
    path: "/configuration/system/:type?/:id?",
    name: "config.system",
    component: () =>
      import(
        /* webpackChunkName: "dashboard" */ "../views/configuration/SystemView.vue"
      ),
    meta: {
      requiresAuth: true,
      title: (params) => t("configuration.titles.system", params),
    },
  },
  {
    path: "/configuration/system/config/:type?/:id?",
    name: "config.system.config",
    component: () =>
      import(
        /* webpackChunkName: "dashboard" */ "../views/configuration/systemConfigView.vue"
      ),
    meta: {
      requiresAuth: true,
      title: (params) => t("configuration.titles.system", params),
    },
  },
  {
    path: "/configuration/system/time/:type?/:id?",
    name: "config.system.time",
    component: () =>
      import(
        /* webpackChunkName: "dashboard" */ "../views/configuration/systemDateTimeView.vue"
      ),
    meta: {
      requiresAuth: true,
      title: (params) => t("configuration.titles.system", params),
    },
  },
];
