export default {
  hasBuildingData(state) {
    return (
      state.accountData.dashboard_modules_config &&
      state.accountData.dashboard_modules_config.filter(
        (e) => e.key == "building.data"
      ).length > 0
    );
  },
  authState(state) {
    return state.authorized;
  },
  devices(state) {
    return state.devices;
  },
  groups(state) {
    return state.groups;
  },
  loadingDataModalVisible(state) {
    return state.loadingDataModalVisible;
  },
};
