<template>
    <div v-if="!ready" class="d-flex h-100 justify-content-center align-items-center">
        <div class="lds-ellipsis">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    </div>
    <div v-else class="h-100">
        <metainfo>
            <template v-slot:title="{ content }">{{
                content ? `${content}` : `Devward`
            }}</template>
        </metainfo>
        <sidebarComponent />
        <div :class="[
            'page-content',
            'd-flex',
            'flex-column',
            sidebarState ? 'active' : '',
        ]">
            <navbarComponent />

            <router-view />
        </div>

        <notifications position="top right" />
        <loading-screen v-if="visible" />
    </div>
</template>

<script>
import { visible } from "@/plugins/loading-screen/loading"
import { useI18n } from "vue-i18n";
import navbarComponent from "@/components/commons/navbarComponent.vue";
import sidebarComponent from "@/components/commons/sidebarComponent.vue";
import * as api from "@/api/index.js";
/**
 * Entry point of the Dashboard App
 */
export default {
    components: {
        sidebarComponent,
        navbarComponent,
    },
    setup() {
        const { t, locale } = useI18n();

        return {
            t,
            visible,
            locale,
        };
    },

    computed: {
        sidebarState() {
            return this.$store.state.sidebarState;
        },
    },
    metaInfo() {
        return {
            title: "Devward",
        };
    },
    data() {
        return {
            ready: false,
            requestInterval: null,
            initialState: {},
        };
    },
    created() {
        document.addEventListener("auth.success", () => {
            this.$loading.show(true);
            this.$store.state.data.sources = [];
            if (this.requestInterval != null) {
                clearInterval(this.requestInterval);
                this.requestInterval = null;
            }
            const getData = async () => {
                await api.getGroups();
                await api.getBuildingData();
                await api.getSensorsData();
            };
            api.getDevices().then(() => {
                getData().then(() => {
                    this.$loading.hide(true);
                });

                this.requestInterval = setInterval(() => {
                    getData();
                    api.getDevices();
                }, 5 * 60 * 1000);
            });
        });
        document.addEventListener("auth.failed", () => {
            if (this.requestInterval != null) {
                clearInterval(this.requestInterval);
                this.requestInterval = null;
            }
        });
        window.addEventListener(
            "load",
            () => {
                this.ready = true;
            },
            { once: true }
        );
    },
};
</script>
<style lang="scss">
@import url("@/scss/app.scss");
</style>