import LoadingComponent from './LoadingComponent.vue';
import {
    ref
} from "vue"

export const visible = ref(false)

export const loading = {
    status: false,
    keep: false,
    show(keepAlive = false) {
        if (!this.keep && keepAlive) {
            this.keep = keepAlive;
        }
        this.status = true;
        visible.value = true
    },
    hide(forced = false) {
        if (this.keep == false || forced) {
            this.keep = false;
            this.status = false;
            visible.value = false
        }
    }
}

export default {
    install: (app) => {
        app.config.globalProperties.$loading = loading;
        app.component('loading-screen', LoadingComponent);
    }
}