import i18n from "@/i18n";
const {
  t
} = i18n.global;

export default [{
  path: "/spaces",
  name: "spaces",
  component: () =>
    import(
      /* webpackChunkName: "dashboard" */
      "../views/spaces/indexView.vue"
    ),
  meta: {
    requiresAuth: true,
    title: (params) => t("headers.spaces", params),
  },
}];