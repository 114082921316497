export default [{
    path: "/data",
    name: "data",
    component: () =>
      import( /* webpackChunkName: "data" */ "../views/data/dataIndex.vue"),
    meta: {
      requiresAuth: true,
      title: "t:titles.data",
    },
  },
  {
    path: "/data/overview",
    name: "data-overview",
    component: () =>
      import(
        /* webpackChunkName: "dataOverview" */
        "../views/data/dataOverview.vue"
      ),

    meta: {
      requiresAuth: true,
      title: "t:titles.data_overview",
    },
  },
  {
    path: "/data/scenes",
    name: "data-scenes",
    component: () =>
      import(
        /* webpackChunkName: "dataScenes" */
        "../views/data/dataScenes.vue"
      ),
    meta: {
      requiresAuth: true,
      title: "t:titles.data_scenes",
    },
  },
];