export default [
  {
    path: "/:pathMatch(.*)*",
    name: "404",
    component: () =>
      import(/* webpackChunkName: "errors" */ "../views/errors/404Error.vue"),
    meta: {
      hideNavbar: true,
      hideSidebar: true,
    },
  },
];
