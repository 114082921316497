/* eslint-disable no-console */

import { register } from "register-service-worker";

if (process.env.ENABLE_SERVICE_WORKER == true) {
  register(`${process.env.BASE_URL}service-worker.js`, {
    registered() {
      const evt = new CustomEvent("service:registered");
      document.dispatchEvent(evt);
    },
    updatefound() {
      const evt = new CustomEvent("service:updatefound");
      document.dispatchEvent(evt);
    },
    updated(registration) {
      const evt = new CustomEvent("service:updated", { detail: registration });
      document.dispatchEvent(evt);
    },
    error(error) {
      console.error("Error during service worker registration:", error);
      const evt = new CustomEvent("service:error", {
        detail: error,
      });
      document.dispatchEvent(evt);
    },
  });
}
