export default [
  {
    path: "/groups",
    name: "groups",
    component: () =>
      import(/* webpackChunkName: "groups" */ "../views/groups/groupsList.vue"),
    meta: {
      requiresAuth: true,
      title: "t:titles.groups",
    },
  },
  {
    path: "/groups/:id/overview",
    name: "groups-overview",
    component: () =>
      import(
        /* webpackChunkName: "groups" */ "../views/groups/groupsOverview.vue"
      ),
    meta: {
      requiresAuth: true,
      title: "t:titles.groups_overview",
    },
  },
];
