import {
  createRouter,
  createWebHistory
} from "vue-router";

import {
  checkSession
} from "@/api/auth"; // @/ mean src/
import {
  getAccountData
} from "@/api/index";

// import routes from files

import dashboard from "./dashboard";
import devices from "./devices";
import groups from "./groups";
import data from "./data";
import auth from "./auth";
import config from "./configuration";
import spaces from "./spaces";
import errors from "./errors";
import {
  loading
} from "@/plugins/loading-screen/loading";

let routes = []; // * here you can define extra routes, but we recommend to use seapared file and concat it below

// concat all the routes
routes = routes
  .concat(dashboard)
  .concat(devices)
  .concat(groups)
  .concat(data)
  .concat(auth)
  .concat(config)
  .concat(spaces)
  .concat(errors);

// create router based on the list we concat last step
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});



router.beforeEach((to) => {
  loading.show()

  var authenticated = checkSession();
  getAccountData();
  if (to.meta.requiresAuth && !authenticated && to.name != "login") {
    return {
      name: "login",
    };
  } else if (authenticated && to.name == "login") {
    return {
      name: "home",
    };
  }
  document.addEventListener(
    "auth.failed",
    () => {
      if (to.meta.requiresAuth && to.name != "login") {
        router.push({
          name: "login"
        });
      }
    }, {
      once: true
    }
  );
  document.addEventListener(
    "auth.success",
    () => {
      if (to.name == "login") {
        router.push({
          name: "home"
        });
      }
    }, {
      once: true
    }
  );
  return true;
});

export default router;