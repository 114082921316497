export default {
  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Español"])},
  "buttons": {
    "accept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aceptar"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Añadir"])},
    "add_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Añadir Grupo"])},
    "add_subgroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Añadir Subgrupo"])},
    "add_measurement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Añadir Medición"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar"])},
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear"])},
    "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuar"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminar"])},
    "load_scene": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cargar Escena"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingresar"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salir"])},
    "read": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escanear QR"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guardar"])},
    "save_scene": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guardar Escena"])},
    "more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostrar más"])},
    "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exportar"])}
  },
  "commons": {
    "select_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccione una opción"])}
  },
  "errors": {
    "400_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bad Request"])},
    "400_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validation error"])},
    "401_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unauthorized"])},
    "401_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Device Blocked"])},
    "401_11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unauthorized to use device"])},
    "401_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unauthenticated"])},
    "401_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bad Credentials"])},
    "401_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unauthorized token"])},
    "401_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unauthorized key"])},
    "401_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unauthorized to manage device"])},
    "401_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unauthorized Device token"])},
    "401_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unauthorized to desync user"])},
    "401_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unauthorized device to make API Requests"])},
    "404_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not Found"])},
    "404_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Route not found"])},
    "404_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Controller not found"])},
    "404_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Method not allowed"])},
    "404_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FindFailed"])},
    "404_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City not found"])},
    "404_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Synchronization not found. The device is not synchronized with the indicated user"])},
    "409_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conflict"])},
    "409_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A conflict occurred with the request because exists another previous synchronization or sync request"])},
    "409_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conflict. The indicated user owns the device, so they should restore the device instead of desync it."])},
    "expired_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Su sesión ha expirado"])},
    "not_implemented": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Funcionalidad no disponible"])},
    "no_scenes_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay escenas públicas disponibles"])},
    "qr": {
      "code_scan_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código invalido, intente nuevamente"])},
      "no_camera_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se ha detectado ninguna cámara"])}
    }
  },
  "headers": {
    "temp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temperatura"])},
    "aqi_general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nivel AQI general"])},
    "aqi_level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nivel AQI"])},
    "data_overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overview"])},
    "configuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuración"])},
    "create_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear grupo"])},
    "edit_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar grupo"])},
    "data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datos"])},
    "devices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dispositivos"])},
    "sync_device": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sincronizar"])},
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
    "groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupos"])},
    "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perfil"])},
    "logger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logger"])},
    "scenes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escenas"])},
    "subgroups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subgrupos"])},
    "top_best_aqi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mejores AQI"])},
    "top_worst_aqi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peores AQI"])}
  },
  "labels": {
    "master_device": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dispositivo Maestro"])},
    "alarms": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Alarma"]), _normalize(["Alarmas"])])},
    "device": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dispositivo"])},
    "devices": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Dispositivo"]), _normalize(["Dispositivos"])])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
    "group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupo"])},
    "group_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre del grupo"])},
    "groups": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Grupo"]), _normalize(["Grupos"])])},
    "interval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intervalo"])},
    "load_as_default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cargar esta escena por defecto"])},
    "load_scene": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cargar Escena"])},
    "measurement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Medida"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre"])},
    "notifications": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Notificación"]), _normalize(["Notificaciones"])])},
    "offline_devices": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Dispositivo offline"]), _normalize(["Dispositivos offline"])])},
    "out_of_date_devices": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Dispositivo desactualizado"]), _normalize(["Dispositivos desactualizados"])])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contraseña"])},
    "save_scene": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guardar Escena"])},
    "scene_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de la escena"])},
    "select_scene": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccione una escena"])},
    "sensors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sensores"])},
    "source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Origen"])},
    "subgroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subgrupo"])},
    "subgroups": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Subgrupo"]), _normalize(["Subgrupos"])])},
    "timerange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rango de visualización"])},
    "timerange_auto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ultimas 24 horas"])},
    "timerange_custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perzonalizado"])},
    "measure_interval_realtime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tiempo Real (intervalo de medición)"])},
    "measure_interval_day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Día"])},
    "measure_interval_hour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hora"])},
    "measure_interval_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes"])},
    "measure_interval_week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semana"])},
    "measure_interval_year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Año"])},
    "measure_interval_year_sum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resumen Anual"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estado"])},
    "pending_configuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuraciones pendiente"])},
    "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualización"])},
    "firmware_version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Firmware"])},
    "hardware_version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hardware"])},
    "firmware_version_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FW"])},
    "hardware_version_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HW"])},
    "waiting_update_devices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esperando configuración"])}
  },
  "values": {
    "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualizado"])},
    "not_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desactualizado"])},
    "offline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offline"])},
    "online": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Online"])},
    "configured": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configurado"])},
    "misconfigured": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desconfigurado"])}
  },
  "messages": {
    "device_name_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El nombre del dispositivo fue actualizado exitosamente."])},
    "device_config_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La configuración del dispositivo fue actualizada exitosamente."])},
    "fetching_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obteniendo datos del servidor"])},
    "new_update_available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nueva actualización disponible. Descargando"])},
    "new_update_available_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se recargará automáticamente la pagina al terminar la actualización"])},
    "updated_installed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se actualizó la aplicación exitosamente, se recargará para aplicar los cambios"])},
    "qr": {
      "permission_request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permiso a la cámara denegado, por favor habilite los permisos de la cámara para continuar"])},
      "read": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escanee el codigo QR"])},
      "scan_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código obtenido exitosamente"])}
    }
  },
  "sensors": {
    "c2h5oh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["C2H5OH (etanol)"])},
    "c3h8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["C3H8 (propano)"])},
    "c4h10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["C4H10 (butano)"])},
    "ch4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CH4 (metano)"])},
    "co": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CO (monóxido de carbono)"])},
    "co2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CO2 (dióxido de carbono)"])},
    "h2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["H2 (hidrógeno)"])},
    "hum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Humedad"])},
    "nh3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NH3 (amoniaco)"])},
    "predicted_tvoc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Predicción de TVOC"])},
    "no2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NO2 (dióxido de nitrógeno)"])},
    "co_raw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valor crudo CO (monóxido de carbono)"])},
    "nh3_raw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valor crudo NH3 (amoniaco)"])},
    "no2_raw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valor crudo NO2 (dióxido de nitrógeno)"])},
    "pm0_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PM0.3 (partículas de 0.3um)"])},
    "pm0_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PM0.5 (partículas de 0.5um)"])},
    "pm10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PM10 (partículas de 10um)"])},
    "pm1_0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PM1.0 (partículas de 1um)"])},
    "pm2_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PM2.5 (partículas de 2.5um)"])},
    "press": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Presión"])},
    "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccionar / Deseleccinar todo"])},
    "temp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temperatura"])},
    "tvoc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TVOC"])},
    "sound_level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nivel de sonido"])},
    "temp_act": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temperatura actual"])},
    "temp_set": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temperatura seleccionada"])},
    "occup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ocupación"])},
    "vent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ventilación"])}
  },
  "aqi": {
    "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N/A"])},
    "good": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bueno"])},
    "moderate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moderado"])},
    "unhealthy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insalubre"])},
    "very_unhealthy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muy Insalubre"])},
    "hazardous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peligroso"])}
  },
  "titles": {
    "data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard ", "|", " Datos"])},
    "data_overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datos ", "|", " Overview"])},
    "data_scenes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datos ", "|", " Escenas"])},
    "devices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dispositivos"])},
    "configuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuración"])},
    "groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupos"])},
    "logger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logger"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login ", "|", " Devward"])},
    "devices_overview": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("device_name")), " ", "|", " Overview"])},
    "groups_overview": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("group_name")), " ", "|", " Overview"])},
    "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
    "smart_roomhub_configuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuración ", "|", " Devward"])}
  },
  "configuration": {
    "titles": {
      "system_info": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("name")), " ", "|", " Información del sistema"])},
      "configuration": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("name")), " ", "|", " Configuración"])},
      "wifi": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("name")), " ", "|", " Wi-Fi"])},
      "system": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("name")), " ", "|", " System"])}
    },
    "headers": {
      "select_source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selección de dispositivo"])},
      "system_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Información del sistema"])}
    },
    "messages": {
      "select_source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccione el dispositivo o grupo que desea configurar"])}
    },
    "labels": {
      "device_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre del dispositivo"])},
      "device_uuid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UUID del dispositivo"])},
      "device_serial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero de serie"])},
      "hardware_version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Versión de hardware"])},
      "firmware_version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Versión de firmware"])},
      "device_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estado"])},
      "times_restarted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veces reiniciado"])},
      "last_restart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Último reinicio"])},
      "last_restart_reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Razón del último reinicio"])},
      "wifi_mode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modo de conexión Wi-Fi"])},
      "wifi_station_connected_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wi-Fi en modo Estación conectado a"])},
      "wifi_station_ip_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dirección IP del Wi-Fi modo Estación"])},
      "wifi_ap_ssid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de Red del Wi-Fi en modo Punto de acceso"])},
      "wifi_ap_ip_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dirección IP del Wi-Fi modo Punto de acceso"])},
      "wifi_ap_mode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modo Punto de Acceso"])},
      "api_endpoint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API Endpoint"])},
      "system_device_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Device Name"])},
      "system_product_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product name"])},
      "system_product_generation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product generation"])},
      "serial_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serial number"])},
      "hardware_revision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hardware revision"])},
      "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update mode"])},
      "conn_ssid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de Red (SSID)"])},
      "conn_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
      "conn_dhcp_client": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cliente DHCP"])},
      "conn_ip_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dirección IP"])},
      "conn_ip_mask": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Máscara IP"])},
      "wifi_net_security": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La red tiene seguridad adicional"])},
      "conn_gateway_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dirección IP Gateway"])},
      "conn_mac_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dirección MAC"])},
      "time_configuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date and time configuration"])},
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
      "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time"])},
      "hvac_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estado del controlador HVAC"])},
      "pps2_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estado de la interfaz Siemens PPS2"])},
      "pps2_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dirección del Siemens PPS2"])},
      "display_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estado de la pantalla"])},
      "display_version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verisón de la pantalla"])}
    },
    "fields": {
      "device_status_online": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Online"])},
      "device_status_offline": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Offline since ", _interpolate(_named("time"))])},
      "wifi_mode": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Station Mode"]), _normalize(["Access Point Mode"]), _normalize(["Station-Access Point Mode"])])},
      "times_restarted": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " time"]), _normalize([_interpolate(_named("n")), " times"])])},
      "automatic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatico"])},
      "manual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manual"])},
      "hvac_status_on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On"])},
      "hvac_status_off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Off"])},
      "pps2_status_ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OK"])},
      "pps2_status_no_comunication": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Comunication"])},
      "display_status": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["OK"]), _normalize(["Error"]), _normalize(["Not Installed"])])}
    }
  }
}