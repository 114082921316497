

<script>
import moment from 'moment'


export default {
    props: {
        series: Object,
        options: {
            type: Object,
            default: () => {
                return {
                    prepend: "",
                    append: ""
                }
            }
        },
        zoom: { type: Boolean, default: false },
        toolbar: { type: Boolean, default: false },
    },
    data() {
        return {
            chartOptions: {
                chart: {
                    height: "100%",
                    type: "line",
                    zoom: {
                        enabled: this.zoom,
                        show: this.zoom,
                    },
                    toolbar: {
                        enabled: this.toolbar,
                        show: this.toolbar,
                    },
                },
                zoom: {
                    type: 'x',
                    autoScaleYaxis: true
                },
                markers: {
                    enabled: false,
                    size: 0
                },
                colors: ['#525050', '#F00', '#0F0', '#00F'],
                stroke: {
                    curve: 'smooth',
                    width: 2
                },
                tooltip: {
                    x: {
                        formatter: function (value) {
                            return moment(value).format("L LTS")
                        }
                    },
                    y: {
                        formatter: (value) => {
                            try {
                                return this.options.prepend + value.toFixed(2) + this.options.append

                            } catch (e) {
                                return this.options.prepend + value + this.options.append
                            }
                        },
                    },
                },
                dataLabels: {
                    enabled: false
                },
                yaxis: {
                    labels: {
                        formatter: function (val) {
                            return (val).toFixed(0);
                        },
                    },
                },

                xaxis: {
                    type: 'datetime',
                    tooltip: {
                        enabled: false
                    },
                    labels: {
                        enabled: true,
                        datetimeUTC: true,
                        datetimeFormatter: {
                            year: 'yyyy',
                            month: 'MMM \'yy',
                            day: 'dd MMM',
                            hour: 'HH:mm',
                        },
                    },
                    markers: {
                        enabled: false
                    }
                },
                noData: {
                    text: "Loading Data..."
                }
            }
        }
    },

}
</script>
<template>
    <div class="h-100 w-100">
        <apexchart ref="chart" type="line" height="100%" :options="chartOptions" :series="series"></apexchart>
    </div>
</template>