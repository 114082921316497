export default {
  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])},
  "buttons": {
    "accept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accept"])},
    "add_measurement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add measurement"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
    "load_scene": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Load Scene"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log in"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log out"])},
    "read": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Read QR"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
    "save_scene": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save Scene"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add"])},
    "add_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Group"])},
    "add_subgroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Subgroup"])},
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create"])},
    "create_space": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Space"])},
    "more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show more"])},
    "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export"])}
  },
  "commons": {
    "select_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select"])}
  },
  "errors": {
    "400_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bad Request"])},
    "400_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validation error"])},
    "401_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unauthorized"])},
    "401_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Device Blocked"])},
    "401_11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unauthorized to use device"])},
    "401_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unauthenticated"])},
    "401_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bad Credentials"])},
    "401_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unauthorized token"])},
    "401_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unauthorized key"])},
    "401_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unauthorized to manage device"])},
    "401_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unauthorized device token"])},
    "401_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unauthorized to desynchronize user"])},
    "401_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unauthorized device to make API requests"])},
    "404_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not Found"])},
    "404_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Route not found"])},
    "404_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Controller not found"])},
    "404_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Method not allowed"])},
    "404_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find failed"])},
    "404_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City not found"])},
    "404_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Device not found. The device is not synchronized with the indicated user"])},
    "409_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conflict"])},
    "409_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A conflict occurred with the request because another previous synchronization or sync request already exists"])},
    "409_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conflict. The indicated user owns the device, so they should restore the device instead of desync it."])},
    "500": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unknown error"])},
    "expired_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Session expired"])},
    "not_implemented": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Functionality not available"])},
    "no_scenes_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Public Scenes Available"])},
    "no_spaces_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Spaces Found"])},
    "qr": {
      "code_scan_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid code, try again"])},
      "no_camera_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Camera not found"])}
    }
  },
  "headers": {
    "temp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temperature"])},
    "aqi_general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overall AQI Level"])},
    "aqi_level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AQI level"])},
    "configuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuration"])},
    "data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data"])},
    "data_overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overview"])},
    "devices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Devices"])},
    "sync_device": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sync"])},
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
    "groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groups"])},
    "general_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information"])},
    "system": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["System"])},
    "spaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spaces"])},
    "wifi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wi-fi"])},
    "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifications"])},
    "create_space": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Space"])},
    "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile"])},
    "scenes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scenes"])},
    "subgroups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subgroups"])},
    "top_best_aqi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Best AQIs"])},
    "top_worst_aqi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Worst AQIs"])},
    "create_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Group"])},
    "edit_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Group"])},
    "logger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logger"])}
  },
  "labels": {
    "energy_validations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Energy Validation"])},
    "energy_validation_occup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Energy Validation - Occupancy"])},
    "energy_validation_occup_forecast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Energy Validation - Occupancy Forecast"])},
    "energy_validation_weather": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Energy Validation - Weather"])},
    "energy_validation_weather_forecast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Energy Validation - Weather Forecast"])},
    "alarms": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Alarm"]), _normalize(["Alarms"])])},
    "device": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Device"])},
    "devices": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Device"]), _normalize(["Devices"])])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
    "space_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Space Name"])},
    "group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group"])},
    "groups": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Group"]), _normalize(["Groups"])])},
    "interval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interval"])},
    "load_as_default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Load this scene by default"])},
    "load_scene": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Load scene"])},
    "measurement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Measurement"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "notifications": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Notification"]), _normalize(["Notifications"])])},
    "offline_devices": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Offline device"]), _normalize(["Offline devices"])])},
    "out_of_date_devices": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Outdated device"]), _normalize(["Outdated devices"])])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
    "save_scene": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save Scene"])},
    "scene_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scene Name"])},
    "select_scene": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Scene"])},
    "data_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data Type"])},
    "sensors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sensors"])},
    "sensor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sensor"])},
    "building": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Building"])},
    "analytics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analytics"])},
    "source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Source"])},
    "subgroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subgroup"])},
    "subgroups": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Subgroup"]), _normalize(["Subgroups"])])},
    "master_device": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Master Device"])},
    "timerange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time Range"])},
    "timerange_auto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last 24 hours"])},
    "timerange_custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom"])},
    "measure_interval_day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daily"])},
    "measure_interval_hour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hourly"])},
    "measure_interval_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monthly"])},
    "measure_interval_realtime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Realtime (Measurement interval)"])},
    "measure_interval_week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weekly"])},
    "measure_interval_year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yearly"])},
    "measure_interval_year_sum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Year summary"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["status"])},
    "pending_configuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuration Pending"])},
    "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update"])},
    "firmware_version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Firmware"])},
    "hardware_version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hardware"])},
    "firmware_version_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FW"])},
    "hardware_version_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HW"])},
    "waiting_update_devices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waiting for configuration"])},
    "group_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group Name"])}
  },
  "values": {
    "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updated"])},
    "not_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outdated"])},
    "offline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disconnected"])},
    "online": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Online"])},
    "configured": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configured"])},
    "misconfigured": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unconfigured"])}
  },
  "messages": {
    "device_name_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The device name was successfully updated."])},
    "device_config_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The device config was successfully updated."])},
    "fetching_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fetching data from server"])},
    "new_update_available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New update available. Downloading"])},
    "new_update_available_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The page will be automatically reloaded when the update is finished"])},
    "updated_installed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The application has been updated successfully, it will reload to apply the changes"])},
    "qr": {
      "permission_request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Camera permission denied, please enable camera permissions to continue"])},
      "read": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scan the QR code"])},
      "scan_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code obtained successfully"])}
    }
  },
  "sensors": {
    "temperature_2m": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temperature 2m"])},
    "relativehumidity_2m": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Relative Humidity 2m"])},
    "dewpoint_2m": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dew Point 2m"])},
    "precipitation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Precipitation"])},
    "rain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rain"])},
    "snowfall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Snowfall"])},
    "pressure_msl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Presure msl"])},
    "surface_pressure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Surface Pressure"])},
    "cloudcover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cloudcover"])},
    "cloudcover_low": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cloud Cover low"])},
    "cloudcover_mid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cloud Cover Mid"])},
    "cloudcover_high": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cloud Cover High"])},
    "windspeed_10m": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wind Speed 10m"])},
    "winddirection_10m": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wind Direction 10m"])},
    "windgusts_10m": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Widn Gusts 10m"])},
    "direct_radiation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Direct Radiation"])},
    "diffuse_radiation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diffuse Radiation"])},
    "is_day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is Day"])},
    "lux": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Light (lux)"])},
    "c2h5oh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["C2H5OH (ethanol)"])},
    "c3h8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["C3H8 (propane)"])},
    "c4h10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["C4H10 (butane)"])},
    "ch4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CH4 (methane)"])},
    "co": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CO (carbon monoxide)"])},
    "co2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CO2 (carbon dioxide)"])},
    "h2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["H2 (hydrogen)"])},
    "hum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Humidity"])},
    "predicted_tvoc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TVOC predicted"])},
    "nh3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NH3 (ammonia)"])},
    "no2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NO2 (nitrogen dioxide)"])},
    "nh3_raw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raw NH3 (ammonia)"])},
    "co_raw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raw CO (carbon monoxide)"])},
    "no2_raw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raw NO2 (nitrogen dioxide)"])},
    "pm0_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PM0.3 (particle matter 0.3um)"])},
    "pm0_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PM0.5 (particle matter 0.5um)"])},
    "pm10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PM10 (particle matter 10um)"])},
    "pm1_0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PM1.0 (particle matter 1um)"])},
    "pm2_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PM2.5 (particle matter 2.5um)"])},
    "press": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pressure (hPa)"])},
    "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select / Deselect all"])},
    "temp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temperature"])},
    "tvoc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TVOC"])},
    "sound_level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sound Level"])},
    "temp_act": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current temperature"])},
    "temp_set": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temperature setpoint"])},
    "occup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Occupancy"])},
    "occup_forecast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Occupancy Forecast"])},
    "weather": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weather"])},
    "weather_forecast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weather Forecast"])},
    "vent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ventilation"])},
    "c2h5oh_measurement_level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["C2H5OH Meas Level"])},
    "c3h8_measurement_level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["C3H8 Meas Level"])},
    "c4h10_measurement_level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["C4H10 Meas Level"])},
    "ch4_measurement_level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CH4 Meas Level"])},
    "co_measurement_level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CO Meas Level"])},
    "co2_measurement_level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CO2 Meas Level"])},
    "h2_measurement_level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["H2 Meas Level"])},
    "hum_measurement_level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Humidity Meas Level"])},
    "nh3_measurement_level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NH3 Meas Level"])},
    "no2_measurement_level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NO2 Meas Level"])},
    "nh3_raw_measurement_level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raw NH3 Meas Level"])},
    "co_raw_measurement_level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raw CO Meas Level"])},
    "no2_raw_measurement_level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raw NO2 Meas Level"])},
    "pm0_3_measurement_level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PM0.3 Meas Level"])},
    "pm0_5_measurement_level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PM0.5 Meas Level"])},
    "pm10_measurement_level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PM10 Meas Level"])},
    "pm1_0_measurement_level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PM1.0 Meas Level"])},
    "pm2_5_measurement_level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PM2.5 Meas Level"])},
    "press_measurement_level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pressure Meas Level"])},
    "temp_measurement_level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temperature Meas Level"])},
    "tvoc_measurement_level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TVOC Meas Level"])},
    "temp_act_measurement_level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temperature Meas Level"])},
    "temp_set_measurement_level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temperature Set Point Meas Level"])},
    "occup_measurement_level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Occupancy Meas Level"])},
    "vent_measurement_level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ventilation Meas Level"])}
  },
  "aqi": {
    "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N/A"])},
    "good": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Good"])},
    "moderate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moderate"])},
    "unhealthy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unhealthy"])},
    "very_unhealthy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Very Unhealthy"])},
    "hazardous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hazardous"])}
  },
  "titles": {
    "data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard ", "|", " Data"])},
    "data_overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data ", "|", " Overview"])},
    "data_scenes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data ", "|", " Scenes"])},
    "devices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Devices"])},
    "configuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuration"])},
    "groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groups"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login ", "|", " Devward"])},
    "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
    "devices_overview": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("device_name")), " ", "|", " Overview"])},
    "groups_overview": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("group_name")), " ", "|", " Overview"])},
    "smart_roomhub_configuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuration ", "|", " Devward"])},
    "logger": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Logger"]), _normalize(["Devward"])])}
  },
  "configuration": {
    "titles": {
      "system_info": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("name")), " ", "|", " System Information"])},
      "configuration": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("name")), " ", "|", " Configuration"])},
      "wifi": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("name")), " ", "|", " Wi-Fi"])},
      "system": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("name")), " ", "|", " System"])}
    },
    "headers": {
      "select_source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Device"])},
      "system_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["System Info"])},
      "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General"])},
      "communication": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Communication"])},
      "building": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Building"])},
      "display": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Display"])}
    },
    "messages": {
      "select_source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select the device or group you want to configure"])}
    },
    "labels": {
      "device_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Device Name"])},
      "device_uuid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Device UUID"])},
      "device_serial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Device Serial Number"])},
      "hardware_version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hardware Version"])},
      "firmware_version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Firmware Version"])},
      "device_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
      "times_restarted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Times Restarted"])},
      "last_restart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Restart"])},
      "last_restart_reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Restart Reason"])},
      "wifi_mode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wi-Fi Mode"])},
      "wifi_station_mode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Station Mode"])},
      "wifi_station_connected_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wi-Fi Station Connected to"])},
      "wifi_station_ip_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wi-Fi Station IP Address"])},
      "wifi_ap_ssid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wi-Fi Access Point SSID"])},
      "wifi_ap_ip_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wi-Fi Access Point IP Address"])},
      "wifi_ap_mode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Access Point Mode"])},
      "api_endpoint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API Endpoint"])},
      "system_device_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Device Name"])},
      "system_product_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product name"])},
      "system_product_generation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product generation"])},
      "serial_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serial number"])},
      "hardware_revision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hardware revision"])},
      "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update mode"])},
      "master_device": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Master Device"])},
      "conn_ssid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Network Name (SSID)"])},
      "conn_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
      "conn_dhcp_client": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DHCP Client"])},
      "conn_ip_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IP Address"])},
      "conn_ip_mask": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IP Mask"])},
      "wifi_net_security": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Network has Security"])},
      "conn_gateway_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gateway IP address"])},
      "conn_mac_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MAC address"])},
      "time_configuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date and time configuration"])},
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
      "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time"])},
      "hvac_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HVAC Controller Status"])},
      "pps2_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siemens PPS2 Interface Status"])},
      "pps2_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siemens PPS2 Address"])},
      "display_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Display Status"])},
      "display_version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Display Version"])}
    },
    "fields": {
      "device_status_online": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Online"])},
      "device_status_offline": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Offline since ", _interpolate(_named("time"))])},
      "wifi_mode": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Station Mode"]), _normalize(["Access Point Mode"]), _normalize(["Station-Access Point Mode"])])},
      "times_restarted": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Once"]), _normalize([_interpolate(_named("n")), " times"])])},
      "automatic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatic"])},
      "manual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manual"])},
      "hvac_status_on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On"])},
      "hvac_status_off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Off"])},
      "pps2_status_ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OK"])},
      "pps2_status_no_comunication": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Comunication"])},
      "display_status": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["OK"]), _normalize(["Error"]), _normalize(["Not Installed"])])}
    }
  }
}