import {
  post,
  get
} from "axios";
import router from "@/router/index";
import cookie from "js-cookie";
import {
  notify
} from "@kyvg/vue3-notification";
import i18n from "@/i18n";
const {
  t
} = i18n.global;

const host = process.env.VUE_APP_URL;

var token = cookie.get("session");
var authorized = false;
export const checkSession = function () {
  if (token != null) {
    get(`${host}/business/auth/user`, {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.data) {
          if (!authorized) {
            const evt = new CustomEvent("auth.success");
            document.dispatchEvent(evt);
          }
          authorized = true;
        }
      })
      .catch(() => {
        const evt = new CustomEvent("auth.failed");
        document.dispatchEvent(evt);
        notify({
          title: t("headers.error"),
          text: t(`errors.expired_session`),
          type: "error",
        });
        router.push({
          name: "login",
        });
        authorized = false;
      });
    return authorized;
  } else {
    authorized = false;
  }
  return authorized;
};

export const authenticate = (credentials) =>
  new Promise((resolve, reject) => {
    post(`${host}/business/auth/login`, credentials, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response.data.success) {
          token = response.data.access_token;
          authorized = true;
          const evt = new CustomEvent("auth.success");
          document.dispatchEvent(evt);
        } else {
          token = null;
          authorized = false;
          const evt = new CustomEvent("auth.failed");
          document.dispatchEvent(evt);
        }
        cookie.set("session", token);

        resolve(authorized);
      })
      .catch((err) => {
        notify({
          title: err,
          type: "error"
        });
        const evt = new CustomEvent("auth.failed");
        document.dispatchEvent(evt);
        reject(err);
      });
  });

export const logout = () => {
  get(`${host}/business/auth/logout`, {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  token = null;
  const evt = new CustomEvent("auth.failed");
  document.dispatchEvent(evt);
  cookie.set("session", token);
  router.push("/login");
};

export default {
  get token() {
    return token;
  },
};