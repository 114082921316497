import { createStore } from "vuex";
import mutations from "./mutations";
import getters from "./getters";
import actions from "./actions";
import modules from "./modules";
import state from "./state";

export default createStore({
  state: state,
  getters: getters,
  mutations: mutations,
  actions: actions,
  modules: modules,
});
