import {
  get,
  post,
  put
} from "axios";
import auth from "@/api/auth";
import moment from "moment";
import store from "@/store/index";
import {
  notify
} from "@kyvg/vue3-notification";
const host = store.state.api.url;

export const getBuildingData = () => {
  return new Promise((resolve, reject) => {
    const start = moment()
      .subtract(1, "days")
      .utc()
      .format("YYYY-MM-DD HH:mm:ss");

    const end = moment().add(1, "day").utc().format("YYYY-MM-DD HH:mm:ss");

    get(`${host}/business/building-data`, {
      params: {
        q: "",
        timestamp_start: start,
        timestamp_end: end,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth.token}`,
        Accept: "application/json",
      },
    })
      .then((res) => {
        if (res.data.success) {
          store.state.buildingData = res.data.data;
        }
        resolve();
      })
      .catch((e) => {
        notify({
          title: e,
          type: "error",
        });
        reject();
      });
  })
};

export const getDeviceBuildingData = (
  device_uuid,
  timestamp_start = null,
  timestamp_end = null
) => {
  return new Promise((resolve) => {
    let data = {
      q: "",
      uuid: device_uuid,
    };
    data.timestamp_start =
      timestamp_start != null ?
        moment(timestamp_start).utc().format("YYYY-MM-DD HH:mm:ss") :
        moment().subtract(1, "day").utc().format("YYYY-MM-DD HH:mm:ss");

    data.timestamp_end =
      timestamp_end != null ?
        moment(timestamp_end).utc().format("YYYY-MM-DD HH:mm:ss") :
        moment().add(1, "day").utc().format("YYYY-MM-DD HH:mm:ss");

    get(`${host}/business/building-data`, {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${auth.token}`,
        "Content-Type": "application/json",
      },
      params: data,
    }).then((res) => {
      if (res.data.success) {
        resolve(res.data.data);
      }
    });
  });
};


export const getDeviceEnergyData = (
  device_uuid,
  timestamp_start = null,
  timestamp_end = null,
  type
) => {
  return new Promise((resolve) => {
    let data = {
      q: "",
      uuid: device_uuid,
      type
    };
    data.timestamp_start =
      timestamp_start != null ?
        moment(timestamp_start).utc().format("YYYY-MM-DD HH:mm:ss") :
        moment().subtract(1, "day").utc().format("YYYY-MM-DD HH:mm:ss");

    data.timestamp_end =
      timestamp_end != null ?
        moment(timestamp_end).utc().format("YYYY-MM-DD HH:mm:ss") :
        moment().add(1, "day").utc().format("YYYY-MM-DD HH:mm:ss");


    get(`${host}/business/cooling`, {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${auth.token}`,
        "Content-Type": "application/json",
      },
      params: data,
    }).then((res) => {
      resolve(res.data.cooling_validations);
    });
  });
};

export const getDeviceAnalyticsData = (
  device_uuid,
  timestamp_start = null,
  timestamp_end = null
) => {
  return new Promise((resolve) => {
    let data = {
      q: "",
      uuid: device_uuid,
    };
    data.timestamp_start =
      timestamp_start != null ?
        moment(timestamp_start).utc().format("YYYY-MM-DD HH:mm:ss") :
        moment().subtract(1, "day").utc().format("YYYY-MM-DD HH:mm:ss");

    data.timestamp_end =
      timestamp_end != null ?
        moment(timestamp_end).utc().format("YYYY-MM-DD HH:mm:ss") :
        moment().add(1, "day").utc().format("YYYY-MM-DD HH:mm:ss");

    get(`${host}/business/analytic/`, {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${auth.token}`,
        "Content-Type": "application/json",
      },
      params: data,
    }).then((res) => {
      if (res.data.success) {
        resolve(res.data.data);
      }
    });
  });
};

export const getDeviceSensorsData = (
  device_uuid,
  timestamp_start = null,
  timestamp_end = null
) => {
  return new Promise((resolve) => {
    let data = {
      q: "",
      uuid: device_uuid,
    };
    data.timestamp_start =
      timestamp_start != null ?
        moment(timestamp_start).utc().format("YYYY-MM-DD HH:mm:ss") :
        moment().subtract(1, "day").utc().format("YYYY-MM-DD HH:mm:ss");

    data.timestamp_end =
      timestamp_end != null ?
        moment(timestamp_end).utc().format("YYYY-MM-DD HH:mm:ss") :
        moment().add(1, "day").utc().format("YYYY-MM-DD HH:mm:ss");

    get(`${host}/business/sensors/data`, {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${auth.token}`,
        "Content-Type": "application/json",
      },
      params: data,
    }).then((res) => {
      if (res.data.success) {
        resolve(res.data.data);
      }
    });
  });
};

export const getSensorsData = () => {
  return new Promise((resolve, reject) => {
    const start = moment()
      .subtract(1, "days")
      .utc()
      .format("YYYY-MM-DD HH:mm:ss");
    const end = moment().add(1, "day").utc().format("YYYY-MM-DD HH:mm:ss");
    const data = {
      q: "",
      timestamp_start: start,
      timestamp_end: end,
    };

    get(`${host}/business/sensors/data`, {
      params: data,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth.token}`,
        Accept: "application/json",
      },
    })
      .then((res) => {
        if (res.data.success) {
          store.state.sensorsData = res.data.data;
        }
        resolve();
      })
      .catch((e) => {
        notify({
          title: e,
          type: "error",
        });
        reject(e);
      });
  });
};

export const getDevices = () => {
  return new Promise((resolve, reject) => {
    get(`${host}/business/devices`, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Bearer ${auth.token}`,
        Accept: "application/json",
      },
    })
      .then((response) => {
        if (response.data && response.data.success) {
          store.state.devices = response.data.devices;
          resolve(response.data.success || false);
        }
      })
      .catch((e) => {
        notify({
          title: e,
          type: "error",
        });
        reject(e);
      });
  });
};

// groups API

export const getGroups = () => {
  return new Promise((resolve, reject) => {
    get(`${host}/business/groups`, {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${auth.token}`,
      },
    })
      .then((response) => {
        if (response.data && response.data.success) {
          store.state.groups = response.data.groups;
        }
        resolve(response.data.success || false);
      })
      .catch((e) => {
        notify({
          title: e,
          type: "error",
        });
        reject(e);
      });
  });
};

export const getGroupData = (id) => {
  return new Promise((resolve, reject) => {
    get(`${host}/business/groups/${id}`, {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${auth.token}`,
      },
    })
      .then((response) => {
        if (response.data.success) {
          resolve(response.data.group.ordered_devices);
        } else {
          resolve([]);
        }
      })
      .catch((e) => {
        notify({
          title: e,
          type: "error",
        });
        reject(e);
      });
  });
};
export const getGroupBuildingData = (id, start, end) => {
  return new Promise((resolve) => {
    if (!start) {
      start = moment()
        .subtract(1, "days")
        .utc()
        .format("YYYY-MM-DD HH:mm:ss");
    }
    if (!end) {
      end = moment().add(1, "day").utc().format("YYYY-MM-DD HH:mm:ss");
    }
    get(`${host}/business/groups/${id}/building-data`, {
      params: {
        q: "",
        timestamp_start: start,
        timestamp_end: end,
      },
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${auth.token}`,
      },
    }).then((response) => {
      if (response.data.success) {
        resolve(response.data.data);
      }
    });
  });
};
export const getGroupSensorsData = (id, start, end) => {
  return new Promise((resolve) => {
    if (!start) {
      start = moment()
        .subtract(1, "days")
        .utc()
        .format("YYYY-MM-DD HH:mm:ss");
    }
    if (!end) {
      end = moment().add(1, "day").utc().format("YYYY-MM-DD HH:mm:ss");
    }

    get(`${host}/business/groups/${id}/sensors-data`, {
      params: {
        q: "",
        timestamp_start: start,
        timestamp_end: end,
      },
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${auth.token}`,
      },
    }).then((response) => {
      if (response.data.success) {
        resolve(response.data.data);
      }
    });
  });
};

export const getGroupChildrens = (id) => {
  return new Promise((resolve, reject) => {
    get(`${host}/business/groups/${id}/subgroups`, {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${auth.token}`,
      },
    })
      .then((response) => {
        if (response.data.success) {
          resolve(response.data.data);
        } else {
          resolve([]);
        }
      })
      .catch((e) => {
        notify({
          title: e,
          type: "error",
        });
        reject(e);
      });
  });
};

export const setDeviceConfig = (device_uuid, config) => {
  return new Promise((resolve) => {
    post(
      `${host}/business/devices/config`, {
      uuid: device_uuid,
      configs: config,
    }, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth.token}`,
      },
    }
    )
      .then(() => {
        resolve();
      })
      .catch((e) => {
        notify({
          text: e,
          type: "error",
        });
      });
  });
};

export const setDeviceName = (device_id, name) => {
  return new Promise((resolve) => {
    post(
      `${host}/business/devices/${device_id}/name`, {
      name: name,
    }, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth.token}`,
      },
    }
    )
      .then(() => {
        resolve();
      })
      .catch((e) => {
        notify({
          text: e,
          type: "error",
        });
      });
  });
};


export const setGroupName = (group_id, name) => {
  return new Promise((resolve) => {
    put(
      `${host}/business/groups/${group_id}`, {
      name: name,
    }, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth.token}`,
      },
    }
    )
      .then(() => {
        resolve();
      })
      .catch((e) => {
        notify({
          text: e,
          type: "error",
        });
      });
  });
};



export const createSpace = (payload) => {
  return new Promise((resolve) => {
    post(
      `${host}/business/spaces`, payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth.token}`,
      },
    }
    )
      .then(() => {
        resolve();
      })
      .catch((e) => {
        notify({
          text: e,
          type: "error",
        });
      });
  });
};

export const getAccountData = () => {
  return new Promise((resolve) => {
    get(`${host}/business/auth/business-data`, {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${auth.token}`,
      },
    }).then((res) => {
      if (res.data.success) {
        store.state.accountData = res.data;
        resolve(res.data);
      }
    });
  });
};