export default {
  setCurrentGroup(state, value) {
    state.currentGroup = value;
  },
  setAuthState(state, value) {
    state.authorized = value;
  },
  setLoadingDataModalVisible(state, value) {
    state.loadingDataModalVisible = value;
  },
};
