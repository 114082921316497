<template>
    <div class="fs-backdrop" :style="{ position: position, display: visible ? 'block' : 'none' }">
        <div class="h-100 d-flex justify-content-center align-items-center">
            <div class="d-flex flex-column justify-content-center align-items-center">
                <div class="lds-ripple">
                    <div></div>
                    <div></div>
                </div>
                <div class="load-label">Loading data</div>
            </div>
        </div>
    </div>
</template>
<script>
import { visible } from "@/plugins/loading-screen/loading"
export default {
    props: {
        position: {
            type: String,
            default: "fixed",
        },
    },
    setup() {
        return { visible }
    },
};
</script>

<style>
.fs-backdrop {
    position: fixed;
    color: white;
    z-index: 99999;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #00000080;
}

.load-label {
    color: white;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
}

.lds-ripple {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}

.lds-ripple div {
    position: absolute;
    border: 4px solid #fff;
    opacity: 1;
    border-radius: 50%;
    animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.lds-ripple div:nth-child(2) {
    animation-delay: -0.5s;
}

@keyframes lds-ripple {
    0% {
        top: 36px;
        left: 36px;
        width: 0;
        height: 0;
        opacity: 0;
    }

    4.9% {
        top: 36px;
        left: 36px;
        width: 0;
        height: 0;
        opacity: 0;
    }

    5% {
        top: 36px;
        left: 36px;
        width: 0;
        height: 0;
        opacity: 1;
    }

    100% {
        top: 0px;
        left: 0px;
        width: 72px;
        height: 72px;
        opacity: 0;
    }
}
</style>