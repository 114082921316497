<template>
    <div v-if="meta('hideSidebar') == false" :class="['sidebar', shown ? 'active' : '']">
        <div class="sidebar-content">
            <div class="sidebar-brand">
                <img src="@/assets/img/icon.png" alt="" class="logo" />
            </div>
            <ul class="sidebar-nav flex-grow-1">
                <li class="nav-item">
                    <router-link class="nav-link" :to="{ name: 'home' }"><i class="fa fa-chart-line"></i>
                        {{ t("headers.overview") }}
                    </router-link>
                </li>
                <li class="nav-item">
                    <a href="#dataMenu" class="nav-link" data-bs-toggle="collapse" aria-expanded="false"><i
                            class="fa fa-rectangle-list"></i>
                        {{ t("headers.data") }}</a>
                    <ul class="sidebar-nav collapse" id="dataMenu">
                        <li class="nav-item">
                            <router-link class="nav-link" :to="{ name: 'data-overview' }"><i class="fa fa-chart-line"></i>
                                {{ t("headers.data_overview") }}</router-link>
                        </li>
                        <li class="nav-item">
                            <router-link class="nav-link" :to="{ name: 'data' }"><i class="fa fa-rectangle-list"></i>
                                {{ t("headers.data") }}
                            </router-link>
                        </li>

                        <!-- <li class="nav-item">
                            <router-link class="nav-link" :to="{ name: 'data-scenes' }"><i class="fa fa-list"></i>
                                {{ t("headers.scenes") }}
                            </router-link>
                        </li> -->
                    </ul>
                </li>
                <li class="nav-item">
                    <a href="#devicesMenu" class="nav-link" data-bs-toggle="collapse" aria-expanded="false"><i
                            class="fa fa-rectangle-list"></i>
                        {{ t("headers.devices") }}</a>
                    <ul class="sidebar-nav collapse" id="devicesMenu">
                        <li class="nav-item">
                            <router-link class="nav-link" :to="{ name: 'devices' }"><i class="fa-solid fa-tablet"></i>
                                {{ t("headers.devices") }}</router-link>
                        </li>
                        <li class="nav-item">
                            <router-link class="nav-link" :to="{ name: 'groups' }"><i class="fa-solid fa-object-group"></i>
                                {{ t("headers.groups") }}</router-link>
                        </li>
                        <li class="nav-item">
                            <router-link class="nav-link" :to="{ name: 'sync' }"><i class="fa-solid fa-tablet"></i>
                                {{ t("headers.sync_device") }}</router-link>
                        </li>
                    </ul>
                </li>
                <!-- <li class="nav-item">
                    <a href="#configMenu" class="nav-link" data-bs-toggle="collapse" aria-expanded="false"><i
                            class="fa fa-rectangle-list"></i>
                        {{ t("headers.configuration") }}</a>
                    <ul class="sidebar-nav collapse" id="configMenu">
                        <li class="nav-item">
                            <router-link class="nav-link" :to="{
                                name: 'config.system.config',
                                params: this.$route.params,
                                query: this.$route.query,
                            }"><i class="fa fa-gear"></i>
                                {{ t("headers.system") }}</router-link>
                        </li>
                        <li class="nav-item">
                            <router-link class="nav-link" :to="{
                                name: 'config.wifi',
                                params: this.$route.params,
                                query: this.$route.query,
                            }"><i class="fa fa-wifi"></i>
                                {{ t("headers.wifi") }}</router-link>
                        </li>
                        <li class="nav-item">
                            <router-link class="nav-link" :to="{ name: null }"><i class="fa fa-bell"></i>
                                {{ t("headers.notifications") }}</router-link>
                        </li>
                    </ul>
                </li> -->
                <li class="nav-item">
                    <router-link class="nav-link" :to="{ name: 'spaces' }"><i class="fa fa-chart-line"></i>
                        {{ t("headers.spaces") }}</router-link>
                </li>
            </ul>
            <ul class="sidebar-nav m-0">
                <li class="nav-item">
                    <a href="javascript:;" @click="logout" class="nav-link"><i style="
                                                            transform-origin: center;
                                                            transform: rotate(180deg);
                                                        " class="fa-solid fa-right-from-bracket"></i>
                        {{ t("buttons.logout") }}</a>
                </li>
            </ul>
        </div>
    </div>
</template>
<script>
import { useI18n } from "vue-i18n";
import { logout } from "@/api/auth";

export default {
    setup() {
        const { t } = useI18n();
        return {
            t,
        };
    },
    computed: {
        shown: {
            get() {
                return this.$store.state.sidebarState;
            },
            set(newValue) {
                this.$store.state.sidebarState = newValue;
            },
        },
    },

    mounted() {
        this.$watch("shown", () => {
            const evt = new CustomEvent("sidebar.toggle", {
                detail: this.shown,
            });
            window.dispatchEvent(evt);
        });

        this.$el.addEventListener("click", (e) => {
            e.stopPropagation();
        });
        document.addEventListener("click", () => {
            this.shown = false;
        });
    },
    methods: {
        logout() {
            logout();
        },
        meta(key) {
            return this.$route.meta[key] || "";
        },
    },
};
</script>

<style>
:root {
    --normal: #fafafa;
    --button: #525050;
    --button-hover: #131313;
    --font: white;
}

.sidebar {
    position: fixed;
    top: 0;
    left: -250px;
    bottom: 0;
    width: 250px;
    z-index: 2000;
    transition: all 0.2s ease-out;
}

.sidebar.active {
    left: 0px;
}

.page-content {
    margin-right: 0;
    transition: all 0.2s ease-out;
    height: 100%;
    max-height: 100vh;
}

.page-content.active {
    margin-left: 0px;
    margin-right: 0px;
}

@media (min-width: 768px) {
    .page-content.active {
        margin-left: 250px;
        margin-right: 0px;
    }
}

.sidebar * {
    color: var(--font) !important;
}

.sidebar .sidebar-content {
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: var(--normal);
    position: relative;
    padding: 10px 0px;
    width: 100%;
    height: 100%;
}

.sidebar .sidebar-brand {
    width: 100%;
    position: relative;
    padding: 10px;
    margin-bottom: 30px;
    font-size: 1.5rem;
    text-align: center;
}

.sidebar .sidebar-brand .logo {
    width: 75%;
    margin: auto;
}

.sidebar .sidebar-nav {
    list-style-type: none;
    padding: 0px 10px;
}

.sidebar .sidebar-nav i {
    font-size: 1.2rem;
}

.sidebar .sidebar-nav .nav-item {
    padding: 2.5px 0px;
}

.sidebar .sidebar-nav .nav-item .nav-link {
    background-color: var(--button);
    border-radius: 10px;
    transition: all 0.3s;
}

.sidebar .sidebar-nav .nav-item .nav-link:hover {
    background-color: var(--button-hover);
}

.sidebar .sidebar-nav .nav-item .nav-link[aria-expanded="false"]::after {
    content: "▼";
    position: absolute;
    right: 15px;
    transition: all 0.2s;
}

.sidebar .sidebar-nav .nav-item .nav-link[aria-expanded="true"]::after {
    content: "▼";
    position: absolute;
    right: 15px;
    transform: rotate(180deg);
    transition: all 0.2s;
}

.sidebar .sidebar-nav .subtitle {
    font-weight: bolder;
    font-size: 1.1rem;
    text-transform: uppercase;
    margin: 0;
    padding: 0px 10px;
}
</style>