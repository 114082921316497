import router from "@/router";
import axios from "axios"

import cookie from "js-cookie"

export const instance = axios.create({
    baseURL: process.env.VUE_APP_URL
})

instance.interceptors.request.use(
    (config) => {
        const token = cookie.get('session');
        if (token) {
            config.headers.common.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => Promise.reject(error)
)

instance.interceptors.response.use(
    response => response,
    error => {
        if (error.response && error.response.status == 401) {
            cookie.set("session", null)
            router.push({
                name: "login"
            })
        }
        return Promise.reject(error)
    }
)
export default {
    install: function (app) {
        app.config.globalProperties.$http = instance;
    }
}