<template>
    <nav
        v-if="meta('hideNavbar') == false"
        class="navbar sticky-top navbar-expand navbar-light bg-white shadow-sm"
    >
        <div class="d-flex w-100 m-0">
            <div class="">
                <div class="navbar-brand">
                    <div class="d-flex h-100 align-items-center">
                        <a
                            :class="[
                                'sidebar-toggle',
                                sidebarState ? 'active' : '',
                            ]"
                            @click="toggleSidebar"
                        >
                            <span></span>
                            <span></span>
                            <span></span>
                        </a>
                    </div>
                </div>
                <!-- <div class="navbar-brand">
                    <a @click="$router.go(-1)" class="nav-link">
                        <i class="fa-solid fa-arrow-left"></i>
                        Back
                    </a>
                </div> -->
            </div>
            <div class="flex-grow-1 d-flex flex-column justify-content-center">
                <p class="m-0 text-center">{{ title }}</p>
            </div>
        </div>
    </nav>
</template>

<script>
    import { useI18n } from "vue-i18n";
    import { logout } from "@/api/auth";
    /**
     * Main dashboard navbar.
     * @displayName Navbar
     */
    export default {
        /**
         * Set up the component
         * @returns {any}
         */
        setup() {
            const { t } = useI18n();
            return {
                t,
                logout,
            };
        },
        metaInfo() {
            return { title: this.title };
        },
        computed: {
            title() {
                const title = this.meta("title");
                try {
                    return title(this.$route.query);
                } catch (e) {
                    // do nothing
                }
                try {
                    if (title.indexOf("t:") > -1) {
                        return this.t(title.substring(2), this.$route.query);
                    }
                } catch (e) {
                    // do nothing
                }
                return title;
            },
            sidebarState: {
                /**
                 * Get the sidebar state from the Vuex store
                 * @returns {Boolean}
                 */
                get() {
                    return this.$store.state.sidebarState;
                },
                /**
                 * @description Set sidebar statate
                 * @param {Boolean} newValue
                 */
                set(newValue) {
                    this.$store.state.sidebarState = newValue;
                },
            },
        },
        methods: {
            /**
             * Get metas from current route
             * @param {any} key
             * @returns {any}
             */
            meta(key) {
                return this.$route.meta[key] || "";
            },
            /**
             * Handle the sidebar state from the navbar
             * @public
             * @param {Event} e Default click PointerEvent
             */
            toggleSidebar(e) {
                e.stopPropagation();
                e.preventDefault();
                this.$store.state.sidebarState = !this.$store.state.sidebarState;
            },
        },
    };
</script>

<style scoped>
.logo {
    height: 2rem;
}

.sidebar-toggle {
    width: 15px;
    height: 10px;
    display: block;
    position: relative;
    cursor: pointer;
}

.sidebar-toggle span {
    display: block;
    height: 2px;
    width: 100%;
    background-color: #444;
    position: absolute;
    transition: all 0.3s ease;
}

.sidebar-toggle span:first-of-type {
    top: 0;
    left: 0;
}

.sidebar-toggle span:not(:first-of-type):not(:last-of-type) {
    left: 0;
    top: calc(50% - 1px);
}

.sidebar-toggle span:last-of-type {
    bottom: 0;
    left: 0;
}

.sidebar-toggle.active span:first-of-type {
    left: 25%;
    top: 40%;
    transform: rotate(45deg);
}

.sidebar-toggle.active span:not(:first-of-type):not(:last-of-type) {
    width: 0;
    left: 50%;
    height: 0;
}

.sidebar-toggle.active span:last-of-type {
    left: 25%;
    bottom: 40%;
    transform: rotate(-45deg);
}
</style>