export default [
  {
    path: "/devices",
    name: "devices",
    component: () =>
      import(
        /* webpackChunkName: "devices" */ "../views/devices/devicesList.vue"
      ),
    meta: {
      requiresAuth: true,
      title: "t:titles.devices",
    },
  },
  {
    path: "/devices/:id/overview",
    name: "devicesOverview",
    component: () =>
      import(
        /* webpackChunkName: "devices" */ "../views/devices/devicesOverview.vue"
      ),
    meta: {
      requiresAuth: true,
      title: "t:titles.devices_overview",
    },
  },
];
